import React, { useEffect, useState } from "react";
import { Avatar, Grid, Dropdown, Space } from "antd";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from 'antd';
import { prepairPictureUrl } from "../../../../utils/ActionUtility";
import { useStytch } from '@stytch/react';
import {
  UserOutlined,
  SettingOutlined,
  PoweroffOutlined,
  DownOutlined,
  BellOutlined,
  CaretRightFilled,
  DoubleRightOutlined,
} from "@ant-design/icons";
import "./index.css";
import { connect } from "react-redux";
import { logoutfn } from "../../../../state/actions/auth";
import { getNotifications } from "../../../../services/user.service";

const { useBreakpoint } = Grid;
const HeaderAvatar: React.FC<any> = ({ user, logoutState }) => {
  const stytchClient = useStytch();
  const { md } = useBreakpoint();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<any>({});
  const [pictureUrl, setPictureUrl] = useState<any>({});
  const [notificationLists, setNotificationLists] = useState<any>([]);

  useEffect(() => {
   if(user && Object.keys(user).length){
      setUserInfo(user);
      setPictureUrl(prepairPictureUrl(user?.picture));
      notification();
    }    
  }, [user]);

   const onLogout = () => {
        stytchClient.session.revoke();        
        logoutState();
        navigate('/login', { replace: true });
   }

  const onProfile = () => {
    navigate('/profile', { replace: true });
  }

  const items: MenuProps = {
    'className': "avtMenuwidth",
    'items': [
      {
        key: "1",
        icon: <Avatar shape="circle" src={pictureUrl} size={40} />,
        label: (
          <a rel="noopener noreferrer">
            {userInfo?.full_name} <br />
            <small className="lowerCase">{userInfo?.email}</small>
          </a>
        ),
      },
      {
        key: "2",
        icon: <UserOutlined />,
        label: <a onClick={onProfile}>My Profile</a>,
      },
      {
        key: "3",
        icon: <SettingOutlined />,
        disabled: true,
        label: <a>Setting</a>,
      },
      {
        key: "4",
        danger: true,
        icon: <PoweroffOutlined className="arrowIcon" />,
        label: <a className="viewAllBtn" onClick={onLogout}>Logout</a>,
      },
    ]
  }
  const notification = async () => {
    const notificationFilter = {
      order: {createdAt: -1 },
      filter: {candidate_id: user.sfdc_id},
      limit: 5
    }
    getNotifications(notificationFilter).then(res=>{
      const notifications:any = res.data || [];
      const items:any = [];
      let lastIndex = 0;
      if(notifications.length){
        notifications.forEach((element:any,index:number) => {
          lastIndex += index
          items.push({
            key: index,
            icon: <CaretRightFilled />,
            label: <a onClick={onView}>{element.message}</a>,
          })
        });
        items.push({
          key: lastIndex,
          danger: true,
          icon: <DoubleRightOutlined className="arrowIcon" />,
          label: <a className="viewAllBtn" onClick={onView}>View All </a>,
        })
      }else{
        items.push({
          key: lastIndex,
          danger: true,
          label: 'No Notification',
        })
      }
      
      
      const notificationLists: MenuProps = {
        'className': "avtMenuwidth notiMenu",
        'items': items
      }
      setNotificationLists(notificationLists);
    });
  }
  const onView = () => {
    navigate('/notification', { replace: true });
  }

  return (
    <Space size={md ? 24 : 16} direction={md ? "horizontal" : "vertical"} className={md ? '' : 'mt-4'}>
      {/* <a>
        <span>  {md ? <MailOutlined className="menuIcon" title="Notification" /> : <span className="menuText">Action</span>}</span>
      </a> */}
      
      <Dropdown menu={notificationLists} placement="bottomRight" trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()} className="avtA">
            <BellOutlined className="menuIcon bellIcon" title="Notification" />
            </a>
      </Dropdown>
      
      <Dropdown menu={items} placement="bottomRight" trigger={["click"]} className="desktopMenu">
        <a onClick={(e) => e.preventDefault()} className="avtA">
          <Avatar
            src={pictureUrl}
            size={35}
            style={{ border: "2px solid #ffff", margin: "0rem 0rem" }}
          />
          {!md && <div className="pl-2">{userInfo?.name} <span className="mr-2">My Profile</span><DownOutlined /></div>}
        </a>
      </Dropdown>
    </Space>
  );
};

const mapStateToProps = (state: any) => {
  return { user: state.reducer.auth.user };
};

function mapDispatchToProps(dispatch: any) {
  return {
    logoutState: () => dispatch(logoutfn())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderAvatar);
// export default HeaderAvatar;
