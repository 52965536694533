import { SET_JOBS, SET_SELECTED_JOB, SET_APPLY_JOB } from "../actions/types";

const initialState = {};

const job = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_JOB:
      return {...state, selectedJob: payload};
    case SET_APPLY_JOB:
      return {...state, applyJob: payload};      
    case SET_JOBS:
      return {...state, jobList: payload};
    default:
      return state;
  }
}

export default job;
