import React, { Fragment } from 'react';
import {  Button, Result, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

const Error404Pages: React.FC = () => {
    const navigate = useNavigate()
   
   const gotoHome = () => {
        navigate('/', { replace: true });
    }
  return (

    <Fragment>
      <Row className="homeHeaderBg ">
                <Col span={24}><h2 className="text-center text-white">&nbsp;</h2></Col>
            </Row>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={gotoHome}>Back Home</Button>}
      />
    </Fragment>
    )
};

export default Error404Pages;