import axios from "axios";
import authHeader from "./auth-header";
import environment from '../environments/environment';
const API_URL = environment.baseUrl+'/api/v1/';

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
}

export const verifyOTP = (personId:any,data:any) => {
  return axios.put(API_URL + "person/validateOtp/"+personId, data);
};
export const getPersonInfo = (personId:any) => {
  return axios.get(API_URL + "person/candidate/"+personId);
};
export const forgotPassword = (email:any) => {
  return axios.post(API_URL + "person/personByEmail",email);
};
export const resetPassword = (id:string,password:string) => {
  return axios.put(API_URL + "person/changePassword/"+id, password);
};
export const resendOTP = (data:any) => {
  return axios.post(API_URL + "person/sentEmailOtp", data);
};

export const uploadImageorFile = (profileId: string,data:any) => {
  const config = {     
      headers: { 'content-type': 'multipart/form-data' }
  }
  return axios.put(API_URL + "person/uploadPicture/"+ profileId, data, config);
};
export const uploadResume = (profileId: string,data:any) => {
  const config = {     
      headers: { 'content-type': 'multipart/form-data' }
  }
  return axios.put(API_URL + "person/uploadResume/"+ profileId, data, config);
};
/**
 * Get All SliderData
 * @param pageSize 
 * @param pageNo 
 * @returns 
 */
export const getAllDropdownData = (pageSize: number = 100, pageNo: Number = 0): Promise<any> => {
  const apiName = ['education', 'experience', 'knowledge']
  const apiUrl: any = [];

  apiName.forEach(name => {
      apiUrl.push(fetch(`${API_URL}/${name}?pageSize=${pageSize}&page=${pageNo}`))
  })
  return Promise.all(apiUrl).then(function (responses) {
      return Promise.all(responses.map(function (response) { return response.json() }));
  }).then(function (promiseData) {
      return {
          education: (promiseData[0].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
          experience: (promiseData[1].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
          knowledge: (promiseData[2].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
      }
  })
}
export const sendCAQEMail = (emailData:any) => {
  return axios.post(API_URL + "jobApplication/sendCAQEmail",emailData);
};
export const getNotifications = (notificationFilter: any = {}) => {
  let query = '';
  if(notificationFilter && notificationFilter.limit){
    query += query.length ? '&': '';
    query += 'limit='+notificationFilter.limit
  }
  if(notificationFilter && notificationFilter.order){
    query += query.length ? '&': '';
    query += 'order='+ JSON.stringify(notificationFilter.order)
  }
  if(notificationFilter && notificationFilter.page){
    query += query.length ? '&': '';
    query += 'page='+notificationFilter.page
  }
  if(notificationFilter && notificationFilter.filter){
    query += query.length ? '&': '';
    query += 'filter='+JSON.stringify(notificationFilter.filter)
  }
  return axios.get(API_URL + "candidateNotification/list?" + query).then(res=>res.data);
};
export default {
  getPublicContent,
  getUserBoard,
  verifyOTP,
  getPersonInfo,
  getAllDropdownData,
  forgotPassword,
  resendOTP,
  sendCAQEMail,
  getNotifications
};