import React, { useCallback } from "react";
import { Grid, Space } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.css";

const { useBreakpoint } = Grid;

const HeaderMenu: React.FC = () => {
  const { md } = useBreakpoint();
  const navigate = useNavigate();
  const onMenuClick = useCallback((event: any) => {
    const { key } = event;
    navigate(`${key}`, { replace: true });
  }, []);

  return (
    <Space size={md ? 24 : 16} direction={md ? "horizontal" : "vertical"}>
      <a onClick={() => navigate(`job-search`, { replace: true })} >       
        <span className="menuText">Job Search</span>
      </a>
      <a onClick={() => navigate(`application`, { replace: true })}>
        <span className="menuText">My Applications</span>
      </a>
    </Space>
  );
};

export default HeaderMenu;
