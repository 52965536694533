import React, { Fragment, useEffect } from "react";
import "./App.css";
import "./assets/css/utilities.css";
import "./assets/css/mediaqueries.css";
import "antd-css-utilities/utility.min.css"; // https://www.npmjs.com/package/antd-css-utilities?activeTab=readme  // https://zahinafsar.github.io/antd-css-utilities/utility.min.css
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getUserInfofn, logoutfn } from "./state/actions/auth";
import AppHeader from "./components/layout/header/AppHeader";
import { Layout } from "antd";
import AppRoute from "./routes/AppRoute";
import { useStytchUser, useStytch } from '@stytch/react';

const App: React.FC<any> = ({ isLoggedIn, getUserProfile, removeAuthState }) => {
  const stytchClient = useStytch();
  const {user } = useStytchUser();  
  // RefereshToken
  useEffect(() => {
    const refresh = () => {     
      if (stytchClient.session.getSync()) {
        stytchClient.session.authenticate({
          session_duration_minutes: 60,
        });
      }
    };
    // Refresh session every 50 minutes
    let interval = setInterval(refresh, 3000000);
    return () => clearInterval(interval);   
  }, [stytchClient]);

  useEffect(() => {
    if (user) {
      const person_id = user && user.trusted_metadata && user.trusted_metadata.person_id ? user.trusted_metadata.person_id : '';
      getUserProfile(person_id);
    } else {
      removeAuthState()
    }
  }, [user]);

  useEffect(() =>{
    //console.log('State::isLoggedIn::', isLoggedIn);
  }, [isLoggedIn])

  return (
    <Fragment>
      <BrowserRouter>
        <Layout>        
          <AppHeader />                   
          <AppRoute />
        </Layout>
      </BrowserRouter>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return { isLoggedIn: state.reducer.auth.isLoggedIn };
};
function mapDispatchToProps(dispatch: any) {
  return {
    getUserProfile: (payload: any) => dispatch(getUserInfofn(payload)),
    removeAuthState: () => dispatch(logoutfn())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
//export default App;
