import React, { ReactNode } from "react";
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import environment from "../../environments/environment";
const stytchClient = new StytchUIClient(environment.auth.STYCH_CLIENT_ID);
interface IProps {
  children?: ReactNode;
}

const AuthStytchProvider = ({ children }: IProps) => {
  // Wrap your app in the StytchProvider and instantiate the StytchUIClient.
  
  return (
    <div>
    <StytchProvider stytch={stytchClient}>
      {children}
    </StytchProvider>
    </div>
  )
}
export default AuthStytchProvider;