import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import searchFilters from "./searchFilters";
import job from "./job";

export default combineReducers({
  auth,
  message,
  searchFilters,
  job
});
