const environment = { 
    envName: process.env.REACT_APP_ENV_NAME|| 'dev',
    production: true,
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    serviceoBaseurl: process.env.REACT_APP_SERVICEO_BASE_URL || '',
    apiVersion: 'api/v1',
    auth: {
        App_Name: 'Talent Portal',
        CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
        CLIENT_DOMAIN: process.env.REACT_APP_AUTH_CLIENT_DOMAIN || '',
        STYCH_CLIENT_ID: process.env.REACT_APP_STYCH_CLIENT_ID || '',
        REDIRECT: process.env.REACT_APP_AUTH_REDIRECT_URL || window.location.origin,
        LOGOUT_URL: process.env.REACT_APP_AUTH_LOGOUT_URL || '',
        CACHE_LOCATION: 'localstorage',
        USE_REFRESH_TOKENS: true,
    },
    resumeBucketUrl:process.env.REACT_APP_RESUME_BUCKET_URL || '',
    accessDocumentUrl: process.env.REACT_APP_ACCESS_DOCUMENT_URL || '',
    linkedInLoginUrl: process.env.REACT_APP_LINKEDIN_LOGIN_URL || '',
    caqUrl: process.env.REACT_APP_CAQ_URL || ''
}
export default environment;