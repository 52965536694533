import React, { ReactNode } from "react";
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

interface IProps {
  component?: ReactNode;
}

const PublicRoute: React.FC<IProps> = ({ component }: IProps) => {
  const auth = useSelector((state: any) => state.reducer.auth);
  const location = useLocation(); 
  if (auth.isLoggedIn) {
     return <Navigate to="/home" state={{ from: location}} replace />
  }

  return <>{component}</>;
};

export default PublicRoute;

