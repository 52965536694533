import { SET_FILTERS, CLEAR_FILTERS } from "../actions/types";

const initialState = {};

const searchFilters = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTERS:
      return {...state, filters: payload, count:Object.keys(payload).length };

    case CLEAR_FILTERS:
      return {...state, filters: {}, count: 0 };

    default:
      return state;
  }
}

export default searchFilters;
