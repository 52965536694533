import axios from "axios";

import environment from '../environments/environment';
const API_URL = environment.baseUrl+'/api/v1/';

export const register = (data:any) => {
  delete data['policies'];
  let signUpData = data;
  const config = {     
      headers: { 'content-type': 'multipart/form-data' }
  }
  return axios.post(API_URL + "person/signUp", signUpData, config);
};

export const emailExist = (email:any, stytch_user_id:any) => {
  const config = {     
    headers: { 'content-type': 'application/json' }
  }
  return axios.post(API_URL + "person/emailExist", {email: email, stytch_user_id: stytch_user_id }, config).then(res => { return res.data && res.data.data ? res.data.data : {} });
};



export const SocialAuthRegister = (data:any) => {  
  let signUpData = data;
  const config = {     
      headers: { 'content-type': 'application/json' }
  }
  return axios.post(API_URL + "person/socialSignup", signUpData, config);
};


export const login = (email: unknown, password:unknown) => {
  return axios
    .post(API_URL+ "person/login", {email,password})
    .then((response:any) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessJwtToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem('redirectUrl');
};

export const userInfo = (personId: unknown) =>{
  return axios
    .get(API_URL+ "person/"+personId)
    .then((response:any) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
}


// export const userInfoByEmail = (email: unknown) =>{
//   return axios
//     .get(API_URL+ "person/"+email)
//     .then((response:any) => {
//       if (response.data) {
//         localStorage.setItem("user", JSON.stringify(response.data.data));
//       }
//       return response.data;
//     });
// }