import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_SINGUP_USER,
  SET_MESSAGE,
} from "./types";

import { register, login, logout, userInfo } from "../../services/auth.service";

export const registerfn = (payload: any) => (dispatch: any) => {
  return register(payload).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data,
      // });

      return Promise.resolve(dispatch({
        type: REGISTER_FAIL,
        payload: response.data,
        data: response.data
      }));
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(dispatch({
        type: REGISTER_FAIL,
        payload: message,
      }));
    }
  );
};

export const loginfn = (payload: any) => (dispatch: any) => {
  return login(payload.email, payload.password).then(
    data => {
      return Promise.resolve(dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
        data: { user: data }
      }));
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(dispatch({
        type: LOGIN_FAIL,
        payload: message,
      }));
    }
  );
};

export const getUserInfofn = (person_id: any) => async (dispatch: any) => { 
  if (person_id) {
    return userInfo(person_id).then(res => {
      if (res) {
        const userData = { ...res.data, ...{ profile: res.profile } };
        dispatch({ type: LOGIN_SUCCESS, payload: { user: userData } })
      } else {
        dispatch({ type: LOGIN_FAIL });
      }
      return Promise.resolve(res);
    }).catch(err => {
      dispatch({ type: LOGIN_FAIL });
      dispatch({ type: SET_MESSAGE, payload: err });
      return Promise.reject(err);
    });
  } 
  // else {
  //   dispatch({ type: LOGIN_FAIL });
  // }
};


export const UserCacheUpdate = (payload: any) => (dispatch: any) => {
  if (payload) {
    dispatch({ type: LOGIN_SUCCESS, payload: { user: payload } })
  }
};

export const logoutfn = () => (dispatch: any) => {
  logout();
  dispatch({ type: LOGOUT });
};

export const setSingupUser = (payload: unknown) => ({
  type: SET_SINGUP_USER,
  payload: payload,
});